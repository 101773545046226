import { PropsWithChildren } from 'react';
import styled from 'styled-components/macro';

import { ParagraphSizes, paragraphs, theme } from 'theme';
import { Devices } from 'types';
import { useDeviceType } from 'common/hooks';

type ParagraphProps = {
  align?: 'unset' | 'left' | 'right' | 'center';
  color?: string;
  size?: ParagraphSizes;
};

export const Paragraph = ({
  children,
  align = 'unset',
  color = theme.colors.white[0],
  size = 'm',
}: PropsWithChildren<ParagraphProps>) => {
  const deviceType = useDeviceType();

  return (
    <Styled.Paragraph align={align} color={color} size={size} deviceType={deviceType}>
      {children}
    </Styled.Paragraph>
  );
};

const Styled = {
  Paragraph: styled.p<Required<ParagraphProps> & { deviceType: Devices }>`
    padding: 0;
    margin: 0;
    font-family: 'DM Sans', sans-serif;
    color: ${({ color }) => color};
    font-size: ${({ size, deviceType }) => paragraphs[size][deviceType].size};
    font-weight: ${({ size, deviceType }) => paragraphs[size][deviceType].weight};
    text-align: ${({ align }) => align};
  `,
};
