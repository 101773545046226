import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    height: 100%;
  }

  body {
    color: ${({ theme }) => theme.colors.white[0]};
    font-family: 'Sora', sans-serif;
    background-color: #0e0e10;
    margin: 0;
    padding: 0;
  }

  button {
    border: none;
    color: inherit;
    background-color: inherit;
    padding: 0;

    &:hover {
      cursor: pointer;
    }
  }

  img {
    max-width:100%;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }
`;
