import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import { FooterSectionType } from 'common/components/Footer';
import { scrollToSection } from 'common/utils';
import { useGlobalContext } from 'context';

export const FooterSection = ({ name, items }: FooterSectionType) => {
  const navigate = useNavigate();
  const { sectionsRefs } = useGlobalContext();
  const { pathname } = useLocation();

  const handleClick = (navigation?: string, refId?: string) => {
    if (navigation) {
      if (pathname !== navigation) {
        const options = refId ? { state: { scrollTo: refId } } : undefined;
        return navigate(navigation, options);
      } else {
        const sectionRef = sectionsRefs.find(({ name }) => name === refId);
        if (sectionRef) {
          scrollToSection(sectionRef.ref);
        }
      }
    }
  };

  return (
    <Styled.FooterSection>
      <span>{name}</span>
      <Styled.FooterSectionList>
        {items.map(({ name, link, navigation, refId }) => (
          <Styled.FooterSectionItem key={name} onClick={() => handleClick(navigation, refId)}>
            {link ? (
              <Styled.Link href={link || '#'}>{name}</Styled.Link>
            ) : (
              <Styled.Button type="button">{name}</Styled.Button>
            )}
          </Styled.FooterSectionItem>
        ))}
      </Styled.FooterSectionList>
    </Styled.FooterSection>
  );
};

const Styled = {
  FooterSection: styled.section`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `,

  FooterSectionList: styled.ul`
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: column;
  `,

  FooterSectionItem: styled.li`
    padding: 8px 0;
    font-size: 14px;
    opacity: 0.75;
  `,

  Link: styled.a`
    color: inherit;
    text-decoration: none;
  `,

  Button: styled.button`
    color: inherit;
    font-weight: inherit;
    font-size: inherit;
    font-family: inherit;
  `,
};
