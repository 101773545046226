import styled, { css } from 'styled-components/macro';

import { Flex, Gradient, Heading, Paragraph } from 'common/components';
import { ArkleyBrincLogo, ParpLogo, SkytradingLogo } from 'assets/SVGs';
import GoogleLogo from 'assets/Images/Logos/GoogleLogo.png';
import { useResponsiveVariant } from 'common/hooks';

export const Timeline = () => {
  const { isMobile, isSmallTablet } = useResponsiveVariant();
  return (
    <Flex direction="column" gap={64}>
      <Flex direction="column" gap={16}>
        <Heading level="h3">Roadmap</Heading>
        <Paragraph size="xxl" color="#CDCED0">
          We’ve already achieved so much, but our journey is far from over!
        </Paragraph>
      </Flex>
      <Styled.Container isMobile={isMobile}>
        {Array.from(Array(8).keys()).map((i) => (
          <Styled.DashedSeparator style={{ gridArea: `dashed-separator${i}` }} key={i} />
        ))}
        {Array.from(Array(6).keys()).map((i) => (
          <Styled.Separator style={{ gridArea: `separator${i}` }} key={i} />
        ))}
        <Styled.Item
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          style={{ gridArea: 'founded' }}
          position={isMobile ? 'right' : 'left'}
        >
          <Flex direction="column" gap={4} align="flex-start">
            <Paragraph size="xs" color="#E1E1E1">
              2020
            </Paragraph>
            <Paragraph size="xl">
              <Gradient>Founded</Gradient>
            </Paragraph>
            <SkytradingLogo />
          </Flex>
        </Styled.Item>
        <Styled.Item
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          style={{ gridArea: 'proof-of-concept' }}
          position="right"
        >
          <Flex direction="column" gap={4} align={isMobile ? 'flex-start' : 'flex-end'}>
            <Paragraph size="xs" color="#E1E1E1">
              2021
            </Paragraph>
            <Paragraph size="xl">
              <Gradient>Proof of concept</Gradient>
            </Paragraph>
          </Flex>
        </Styled.Item>
        <Styled.Item
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          style={{ gridArea: 'pre-seed' }}
          position={isMobile ? 'right' : 'left'}
        >
          <Paragraph size="xs" color="#E1E1E1">
            2022
          </Paragraph>
          <Paragraph size="xl">
            <Gradient>Pre–seed round</Gradient>
          </Paragraph>
          <Paragraph size="xs" color="#E1E1E1">
            $250K - EU funding received
          </Paragraph>
          <ParpLogo />
        </Styled.Item>
        <Styled.Item
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          style={{ gridArea: 'prototype' }}
          position="right"
        >
          <Flex direction="column" gap={4} align={isMobile ? 'flex-start' : 'flex-end'}>
            <Paragraph size="xs" color="#E1E1E1">
              2022
            </Paragraph>
            <Paragraph size="xl">
              <Gradient>Prototype</Gradient>
            </Paragraph>
          </Flex>
        </Styled.Item>
        <Styled.Item
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          style={{ gridArea: 'seed' }}
          position={isMobile ? 'right' : 'left'}
        >
          <Paragraph size="xs" color="#E1E1E1">
            2023
          </Paragraph>
          <Paragraph size="xl">
            <Gradient>Seed round</Gradient>
          </Paragraph>
          <Paragraph size="xs" color="#E1E1E1">
            $1M - Arkley Brinc VC funding received
          </Paragraph>
          <ArkleyBrincLogo />
        </Styled.Item>
        <Styled.Item
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          style={{ gridArea: 'google-program' }}
          position="right"
        >
          <Flex direction="column" gap={4} align={isMobile ? 'flex-start' : 'flex-end'}>
            <Paragraph size="xs" color="#E1E1E1">
              2023
            </Paragraph>
            <Paragraph size="xl" align={isMobile ? 'left' : 'right'}>
              <Gradient>Google for Startup Cloud Program</Gradient>
            </Paragraph>
            <Paragraph size="xs" color="#E1E1E1">
              $100K - Google funding received
            </Paragraph>
            <img src={GoogleLogo} alt="google logo" />
          </Flex>
        </Styled.Item>
        <Styled.Item
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          style={{ gridArea: 'demo' }}
          position={isMobile ? 'right' : 'left'}
        >
          <Flex direction="column" gap={4} align="flex-start">
            <Paragraph size="xs" color="#E1E1E1">
              2023
            </Paragraph>
            <Paragraph size="xl">
              <Gradient>First Demo</Gradient>
            </Paragraph>
            <Paragraph size="xs" color="#E1E1E1">
              MVP available for selected users.
            </Paragraph>
          </Flex>
        </Styled.Item>
        <Styled.Item isMobile={isMobile} isSmallTablet={isSmallTablet} style={{ gridArea: 'charts' }} position="right">
          <Flex direction="column" gap={4} align={isMobile ? 'flex-start' : 'flex-end'}>
            <Paragraph size="xs" color="#E1E1E1">
              2024
            </Paragraph>
            <Paragraph size="xl">
              <Gradient>TradingView Charts</Gradient>
            </Paragraph>
            <Paragraph size="xs" color="#E1E1E1">
              Partnership
            </Paragraph>
          </Flex>
        </Styled.Item>
        <Styled.Item
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          style={{ gridArea: 'backtester' }}
          position={isMobile ? 'right' : 'left'}
        >
          <Flex direction="column" gap={4} align="flex-start">
            <Paragraph size="xs" color="#E1E1E1">
              2024
            </Paragraph>
            <Paragraph size="xl">
              <Gradient>Backtester Release</Gradient>
            </Paragraph>
            <Paragraph size="xs" color="#E1E1E1">
              We are here!
            </Paragraph>
          </Flex>
        </Styled.Item>
        <Styled.Item
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          style={{ gridArea: 'assistant' }}
          position="right"
        >
          <Flex direction="column" gap={4} align={isMobile ? 'flex-start' : 'flex-end'}>
            <Paragraph size="xs" color="#E1E1E1">
              TBA
            </Paragraph>
            <Paragraph size="xl">
              <Gradient>AI Trading Assistant</Gradient>
            </Paragraph>
          </Flex>
        </Styled.Item>
        <Styled.Item
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          style={{ gridArea: 'optimizer' }}
          position={isMobile ? 'right' : 'left'}
        >
          <Flex direction="column" gap={4} align="flex-start">
            <Paragraph size="xs" color="#E1E1E1">
              TBA
            </Paragraph>
            <Paragraph size="xl">
              <Gradient>AI Strategy Optimizer</Gradient>
            </Paragraph>
          </Flex>
        </Styled.Item>
        <Styled.Item isMobile={isMobile} isSmallTablet={isSmallTablet} style={{ gridArea: 'rewards' }} position="right">
          <Flex direction="column" gap={4} align={isMobile ? 'flex-start' : 'flex-end'}>
            <Paragraph size="xs" color="#E1E1E1">
              TBA
            </Paragraph>
            <Paragraph size="xl">
              <Gradient>Rewards Systems</Gradient>
            </Paragraph>
          </Flex>
        </Styled.Item>
        <Styled.Item
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          style={{ gridArea: 'bots' }}
          position={isMobile ? 'right' : 'left'}
        >
          <Flex direction="column" gap={4} align="flex-start">
            <Paragraph size="xs" color="#E1E1E1">
              TBA
            </Paragraph>
            <Paragraph size="xl">
              <Gradient>Live Trading Bots</Gradient>
            </Paragraph>
          </Flex>
        </Styled.Item>
        <Styled.Item
          isMobile={isMobile}
          isSmallTablet={isSmallTablet}
          style={{ gridArea: 'marketplace' }}
          position="right"
        >
          <Flex direction="column" gap={4} align={isMobile ? 'flex-start' : 'flex-end'}>
            <Paragraph size="xs" color="#E1E1E1">
              TBA
            </Paragraph>
            <Paragraph size="xl">
              <Gradient>Marketplace</Gradient>
            </Paragraph>
          </Flex>
        </Styled.Item>
      </Styled.Container>
    </Flex>
  );
};

const Styled = {
  Container: styled.section<{ isMobile: boolean }>`
    min-height: 700px;
    display: grid;
    grid-template-columns: 1fr, 8px, 1fr;
    grid-template-rows: repeat(150px, 5);
    grid-template-areas:
      'founded dashed-separator0 .'
      '. dashed-separator1 proof-of-concept'
      'pre-seed dashed-separator2 .'
      '. dashed-separator3 prototype'
      'seed dashed-separator4 .'
      '. dashed-separator5 google-program'
      'demo dashed-separator6 .'
      '. dashed-separator7 charts'
      'backtester separator0 .'
      '. separator1 assistant'
      'optimizer separator2 .'
      '. separator3 rewards'
      'bots separator4 .'
      '. separator5 marketplace';

    ${({ isMobile }) =>
      isMobile &&
      css`
        grid-template-areas:
          'dashed-separator0 founded'
          'dashed-separator1 proof-of-concept'
          'dashed-separator2 pre-seed'
          'dashed-separator3 prototype'
          'dashed-separator4 seed'
          'dashed-separator5 google-program'
          'dashed-separator6 demo'
          'dashed-separator7 charts'
          'separator0 backtester'
          'separator1 assistant'
          'separator2 optimizer'
          'separator3 rewards'
          'separator4 bots'
          'separator5 marketplace';
      `}
  `,

  Item: styled.article<{ position: 'left' | 'right'; isMobile: boolean; isSmallTablet?: boolean }>`
    width: ${({ isSmallTablet }) => (isSmallTablet ? '340px' : '400px')};
    height: ${({ isMobile }) => (isMobile ? 'auto' : '124px')};
    gap: 4px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #e1e1e1;
    padding: ${({ position, isMobile }) =>
      position === 'left' ? '32px 52px 0 0' : isMobile ? '32px 0 52px 52px' : '32px 0 0 52px'};
    overflow: 'visible';

    * {
      overflow: visible;
    }
  `,

  DashedSeparator: styled.div<{ first?: boolean }>`
    width: 8px;
    background-color: #e1e1e11a;
    position: relative;

    ${({ first }) =>
      !first &&
      css`
        &::after {
          content: '';
          position: absolute;
          width: 16px;
          height: 16px;
          top: 0px;
          left: 4px;
          transform: translate(-50%, -50%);
          background-color: ${({ theme }) => theme.colors.white[0]};
          border-radius: 50%;
        }

        &::before {
          content: '';
          position: absolute;
          width: 32px;
          height: 32px;
          top: 0px;
          left: 4px;
          transform: translate(-50%, -50%);
          background-color: #e1e1e11a;
          border-radius: 50%;
        }
      `}
  `,

  Separator: styled.div`
    background: #e1e1e11a;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0px;
      left: 4px;
      transform: translate(-50%, -50%);
      background-color: ${({ theme }) => theme.colors.white[0]};
      border-radius: 50%;
    }

    &::before {
      content: '';
      position: absolute;
      width: 32px;
      height: 32px;
      top: 0px;
      left: 4px;
      transform: translate(-50%, -50%);
      background-color: #e1e1e11a;
      border-radius: 50%;
    }
  `,
};
