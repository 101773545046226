import { ButtonHTMLAttributes, CSSProperties, PropsWithChildren } from 'react';
import styled, { css } from 'styled-components/macro';

import { ButtonColors, theme } from 'theme';
import { CircleLoader } from 'common/components';
import { TickIcon } from 'assets/SVGs';

enum ButtonHeight {
  'S' = 'auto',
  'M' = '40px',
  'L' = '48px',
  'XL' = '64px',
}

type Variant = 'default' | 'primary' | 'ghost' | 'outlined' | 'gradient';
type VaraintStyle = {
  background?: ButtonColors;
  border?: ButtonColors;
};
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'S' | 'M' | 'L' | 'XL';
  variant?: Variant;
  customWidth?: string;
  fitWidth?: boolean;
  justify?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  visibility?: CSSProperties['visibility'];
  loading?: boolean;
  success?: string;
  noPadding?: boolean;
}

export const Button = ({ children, loading, noPadding, success, ...props }: PropsWithChildren<ButtonProps>) => {
  const getStylesForVariant = (variant?: Variant) => {
    if (variant === 'outlined') {
      return {
        border: theme.buttons.primary,
      };
    }

    return {
      background: theme.buttons[variant ?? 'default'],
    };
  };

  return (
    <Styled.Button {...props} variantStyle={getStylesForVariant(props.variant)} noPadding={noPadding}>
      {loading ? (
        <CircleLoader size="S" background={getStylesForVariant(props.variant).background?.inactive} />
      ) : success ? (
        <>
          <TickIcon /> {success}
        </>
      ) : (
        children
      )}
    </Styled.Button>
  );
};

const Styled = {
  Button: styled.button<ButtonProps & { variantStyle: VaraintStyle }>`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white[0]};
    width: fit-content;
    display: flex;
    align-items: center;
    height: ${({ size = 'M' }) => ButtonHeight[size]} !important;
    justify-content: ${({ justify = 'center' }) => justify};
    background: ${({ variantStyle: { background } }) => (background ? background.default : 'inherit')};
    border-radius: 5px;
    gap: 8px;
    font-size: ${({ size }) => (size === 'S' ? '14px' : '16px')};
    font-weight: ${({ variant }) => (variant === 'ghost' ? 400 : 600)};
    padding: ${({ size, noPadding }) =>
      size === 'S' ? '4px 8px' : noPadding ? 0 : size === 'XL' ? '8px 16px' : '6px 10px'};
    visibility: ${({ visibility }) => visibility ?? 'visible'};
    white-space: nowrap;

    ${({ fitWidth }) =>
      fitWidth &&
      css`
        flex: 1;
      `}

    ${({ customWidth }) =>
      customWidth &&
      css`
        width: ${customWidth};
      `}

      ${({ variantStyle }) =>
      variantStyle.border &&
      css`
        border: 2px solid ${variantStyle.border.default};
      `}

    &:hover {
      background: ${({ variantStyle: { background } }) => (background ? background.hover : 'inherit')};
      border: 2px solid ${({ variantStyle: { border } }) => (border ? border.hover : 'none')};
    }

    &:active {
      background: ${({ variantStyle: { background } }) => (background ? background.pressed : 'inherit')};
      border: 2px solid ${({ variantStyle: { border } }) => (border ? border.pressed : 'none')};
    }

    &:disabled {
      opacity: 0.5;
      background: ${({ variantStyle: { background } }) => (background ? background.inactive : 'inherit')};
      border: 2px solid ${({ variantStyle: { border } }) => (border ? border.inactive : 'none')};

      &:hover {
        cursor: default;
      }
    }
  `,
};
