import styled from 'styled-components/macro';

import { Flex, FooterSectionType, PartnersLogos } from 'common/components';
import { SkytradingLogo } from 'assets/SVGs';
import { FooterInfoWithMedia, FooterSection } from 'common/components/Footer/components';
import EuropeanFoundBanner from 'assets/Images/Rest/EuropeanFoundBanner.png';

type MobileFooterProps = {
  footerSections: FooterSectionType[];
};

export const MobileFooter = ({ footerSections }: MobileFooterProps) => (
  <>
    <Styled.Container>
      <PartnersLogos showTrustedLabel />
      <Flex direction="column" gap={32} align="flex-start">
        <Styled.InnerWrapper>
          <SkytradingLogo />
        </Styled.InnerWrapper>
        {footerSections.map((item) => (
          <FooterSection {...item} key={item.name} />
        ))}
        <FooterInfoWithMedia />
      </Flex>
    </Styled.Container>
    <Styled.ParpContainer>
      <img src={EuropeanFoundBanner} style={{ placeSelf: 'center' }} />
    </Styled.ParpContainer>
  </>
);

const Styled = {
  Container: styled.footer`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 120px;
    padding: 24px 16px;
  `,
  InnerWrapper: styled.div`
    align-self: center;
  `,

  ParpContainer: styled.div`
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
  `,
};
