import styled from 'styled-components/macro';
import { useCookieConsentContext } from '@use-cookie-consent/react';

import { Button } from 'common/components';
import { useResponsiveVariant } from 'common/hooks';

export const CookieBanner = () => {
  const { acceptAllCookies, declineAllCookies } = useCookieConsentContext();
  const isCookiesSet = localStorage.getItem('isCookiesSet');
  const { isMobile } = useResponsiveVariant();

  if (isCookiesSet) return null;

  const closeBanner = () => {
    localStorage.setItem('isCookiesSet', 'true');
  };

  return (
    <Styled.Container isMobile={isMobile}>
      <Styled.Paragraph>We use cookies to analyze web traffic.</Styled.Paragraph>
      <Styled.ButtonsContainer>
        <Button
          size="S"
          onClick={() => {
            acceptAllCookies();
            closeBanner();
          }}
        >
          Accept
        </Button>
        <Button
          size="S"
          onClick={() => {
            declineAllCookies();
            closeBanner();
          }}
        >
          Reject
        </Button>
      </Styled.ButtonsContainer>
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.div<{ isMobile: boolean }>`
    position: fixed;
    bottom: 5vh;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
    gap: ${({ isMobile }) => (isMobile ? '2px' : '20px')};
    background-color: ${({ theme }) => theme.colors.white[1]};
    padding: 8px 16px;
    border-radius: 10px;
    z-index: 100;
    align-items: center;
  `,
  Paragraph: styled.p`
    color: ${({ theme }) => theme.colors.black[0]};
    white-space: nowrap;
  `,
  ButtonsContainer: styled.div`
    display: flex;
    justify-content: center;
    gap: 12px;
  `,
};
