import { Navigation } from 'Navigation';
import { GlobalContextProvider } from 'context';
import { CookieBanner } from 'modules/LandingPage/components';

export const App = () => (
  <GlobalContextProvider>
    <Navigation />
    <CookieBanner />
  </GlobalContextProvider>
);
