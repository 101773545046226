import styled from 'styled-components';

import { FacebookLogo, LinkedInLogo, TwitterLogo } from 'assets/SVGs';

export const FooterInfoWithMedia = () => (
  <Styled.Container>
    <Styled.Media>
      <a href="https://www.facebook.com/profile.php?id=100087282576382" target="_blank" rel="noreferrer noopener">
        <FacebookLogo />
      </a>
      <a href="https://twitter.com/skytrading_io" target="_blank" rel="noreferrer noopener">
        <TwitterLogo />
      </a>
      <a
        href="https://www.linkedin.com/company/skytrading-io/?viewAsMember=true"
        target="_blank"
        rel="noreferrer noopener"
      >
        <LinkedInLogo />
      </a>
    </Styled.Media>
  </Styled.Container>
);

const Styled = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 16px;
  `,
  Media: styled.div`
    display: flex;
    gap: 16px;
  `,
};
