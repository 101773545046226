import { PropsWithChildren, HTMLAttributes } from 'react';
import styled from 'styled-components/macro';

import { HeadingLevels, headings } from 'theme';
import { Devices } from 'types';
import { useDeviceType } from 'common/hooks';

interface HeadingProps extends HTMLAttributes<HTMLElement> {
  level: HeadingLevels;
  align?: 'unset' | 'left' | 'right' | 'center';
  color?: string;
  margin?: number;
}

export const Heading = ({ children, ...props }: PropsWithChildren<HeadingProps>) => {
  const deviceType = useDeviceType();

  return (
    <Styled.Text as={props.level} {...props} deviceType={deviceType}>
      {children}
    </Styled.Text>
  );
};

const Styled = {
  Text: styled.div<HeadingProps & { deviceType: Devices }>`
    margin: ${({ margin = 0 }) => `0 0 ${margin}px`};
    color: ${({ theme, color = theme.colors.white[0] }) => color};
    font-size: ${({ level, deviceType }) => headings[level][deviceType].size};
    font-weight: ${({ level, deviceType }) => headings[level][deviceType].weight};
    text-align: ${({ align = 'unset' }) => align};
  `,
};
