import styled from 'styled-components/macro';

import { Gradient, Heading, Paragraph } from 'common/components';

type CardProps = {
  title: string;
  content: string;
  image?: string;
  variant?: 'vertical' | 'horizontal';
};

export const Card = ({ title, content, image, variant = 'vertical' }: CardProps) => (
  <Styled.Container variant={variant}>
    {image && <img src={image} alt={image} width={240} height={240} />}
    <Heading level="h6" align="center">
      <Gradient>{title}</Gradient>
    </Heading>
    <Paragraph align="center">{content}</Paragraph>
  </Styled.Container>
);

const Styled = {
  Container: styled.section<Pick<CardProps, 'variant'>>`
    width: ${({ variant }) => (variant === 'horizontal' ? '400px' : '340px')};
    height: ${({ variant }) => (variant === 'horizontal' ? '250px' : '520px')};
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;
    border-radius: 16px;
    background: linear-gradient(306.76deg, rgba(42, 42, 42, 0.32) 49.14%, rgba(75, 75, 75, 0.32) 113.65%);
  `,
};
