import {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  MutableRefObject,
} from 'react';

export type SectionRef = {
  ref: MutableRefObject<HTMLElement | null>;
  name: string;
};

type GlobalContextType = {
  sectionsRefs: SectionRef[];
  setSectionsRefs: Dispatch<SetStateAction<SectionRef[]>>;
};

const GlobalContext = createContext<GlobalContextType>({
  sectionsRefs: [],
  setSectionsRefs: () => {
    return;
  },
});

export const GlobalContextProvider = ({ children }: PropsWithChildren) => {
  const [sectionsRefs, setSectionsRefs] = useState<SectionRef[]>([]);

  return <GlobalContext.Provider value={{ sectionsRefs, setSectionsRefs }}>{children}</GlobalContext.Provider>;
};

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);

  return context;
};
