import { Layout } from 'common/components';

import { Wall } from './components';

export const LandingPage = () => {
  return (
    <Layout>
      <Wall />
    </Layout>
  );
};
