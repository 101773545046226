import { Flex, Paragraph } from 'common/components';
import { useResponsiveVariant } from 'common/hooks';
import GoogleLogo from 'assets/Images/Logos/GoogleLogo.png';
import PolygonLogo from 'assets/Images/Logos/PolygonLogo.png';
import SanostroLogo from 'assets/Images/Logos/SanostroLogo.png';
import TradingviewLogo from 'assets/Images/Logos/TradingviewLogo.png';
import WhitebitLogo from 'assets/Images/Logos/WhitebitLogo.png';
import ZimtraLogo from 'assets/Images/Logos/ZimtraLogo.png';

type PartnersLogosProps = {
  showTrustedLabel?: boolean;
};

export const PartnersLogos = ({ showTrustedLabel }: PartnersLogosProps) => {
  const { isMobile, isTabletOrMobile } = useResponsiveVariant();

  return (
    <Flex gap={32} direction={isTabletOrMobile ? 'column' : 'row'}>
      {showTrustedLabel && <Paragraph>Trusted by:</Paragraph>}
      <Flex gap={isMobile ? 16 : 24} direction={isMobile ? 'column' : 'row'}>
        <Flex gap={24}>
          <a href="https://cloud.google.com/" target="blank">
            <img src={GoogleLogo} alt="Google logo" />
          </a>
          <a href="https://polygon.io/" target="blank">
            <img src={PolygonLogo} alt="Polygon logo" />
          </a>
          <a href="https://sanostro.com/" target="blank">
            <img src={SanostroLogo} alt="Sanostro logo" />
          </a>
        </Flex>
        <Flex gap={24}>
          <a href="https://www.tradingview.com/" target="blank">
            <img src={TradingviewLogo} alt="Tradingview logo" />
          </a>
          <a href="https://whitebit.com/" target="blank">
            <img src={WhitebitLogo} alt="Whitebit logo" />
          </a>
          <a href="https://zimtra.ky/" target="blank">
            <img src={ZimtraLogo} alt="Zimtra logo" />
          </a>
        </Flex>
      </Flex>
    </Flex>
  );
};
