import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';
import styled from 'styled-components/macro';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: ReactNode;
  error?: FieldError;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({ name, label, error, ...props }, ref) => {
  return (
    <>
      <Styled.Container>
        {label}
        <Styled.Input id={name} name={name} type="checkbox" ref={ref} {...props} />
        <Styled.Checkmark className="checkmark" />
      </Styled.Container>
      {error && <Styled.Error>{error.message}</Styled.Error>}
    </>
  );
});

Checkbox.displayName = 'Checkbox';

const Styled = {
  Container: styled.label`
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    user-select: none;

    input:checked ~ .checkmark {
      border-color: ${({ theme }) => theme.buttons.primary.default};
      background-color: ${({ theme }) => theme.buttons.primary.default};
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    &:hover input ~ .checkmark {
      border-color: ${({ theme }) => theme.buttons.default.hover};
    }

    &:hover input:checked ~ .checkmark {
      border-color: ${({ theme }) => theme.buttons.primary.hover};
      background-color: ${({ theme }) => theme.buttons.primary.hover};
    }
  `,

  Checkmark: styled.span`
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 18px;
    width: 18px;
    border: 2px solid ${({ theme }) => theme.buttons.default.default};
    border-radius: 2px;
    margin: 2px;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 5px;
      top: 2px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  `,

  Input: styled.input`
    position: absolute;
    appearance: none;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  `,

  Error: styled.span`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.red[2]};
  `,
};
