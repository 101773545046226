import { MutableRefObject } from 'react';

export const scrollToSection = (
  ref?: MutableRefObject<HTMLElement | null>,
  setIsNavListExpanded?: (isExpanded: boolean) => void,
) => {
  if (setIsNavListExpanded) {
    setIsNavListExpanded(false);
  }
  if (ref && ref.current) {
    ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};
