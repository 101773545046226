import { useResponsiveVariant } from 'common/hooks';

import { DesktopFooter, MobileFooter } from './components';

export const Footer = () => {
  const { isMobile } = useResponsiveVariant();

  const footerSections = [
    {
      name: 'Company',
      items: [
        { name: 'About us', navigation: '/about' },
        { name: 'Disclamer', navigation: '/disclamer' },
      ],
    },
    {
      name: 'Product',
      items: [
        { name: 'Strategy creator', refId: 'Strategy creator', navigation: '/' },
        { name: 'AI Assistant', refId: 'AI Assistant', navigation: '/' },
        { name: 'AI optimizer', refId: 'AI optimizer', navigation: '/' },
      ],
    },
    { name: 'Support', items: [{ name: 'support@skytrading.io' }] },
  ];

  return isMobile ? (
    <MobileFooter footerSections={footerSections} />
  ) : (
    <DesktopFooter footerSections={footerSections} />
  );
};
