import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Flex, Heading, Paragraph, WaitListSection, Layout, Card } from 'common/components';
import { useResponsiveVariant } from 'common/hooks';
import { ArkleyBrincLogo, ParpLogo, StarIcon } from 'assets/SVGs';
import image1 from 'assets/Images/TeamPhotos/image1.png';
import image2 from 'assets/Images/TeamPhotos/image2.png';
import image3 from 'assets/Images/TeamPhotos/image3.png';
import image4 from 'assets/Images/TeamPhotos/image4.png';
import image5 from 'assets/Images/TeamPhotos/image5.png';
import image6 from 'assets/Images/TeamPhotos/image6.png';
import image7 from 'assets/Images/TeamPhotos/image7.png';
import image8 from 'assets/Images/TeamPhotos/image8.png';
import image9 from 'assets/Images/TeamPhotos/image9.png';

import { Timeline } from './components';

const coreValues = [
  {
    title: 'Empowering our people',
    content: 'We invest in training and development for each team member, helping them reach their full potential.',
  },
  {
    title: 'Building strong connections',
    content: 'We foster lasting relationships within the team, with our users, and with our business partners.',
  },
  {
    title: 'Open communication ',
    content:
      'We actively listen to our users and colleagues alike. This allows us to create products and services that truly address user needs.',
  },
];

export const AboutView = () => {
  const navigate = useNavigate();
  const { isSmallMobile, isMobile, isSmallTablet } = useResponsiveVariant();

  return (
    <Layout>
      <Styled.Container>
        <Flex direction="column" gap={80} width={'100%'}>
          <Flex direction="column" gap={16}>
            <Heading level="h2" align="center">
              Meet SkyTrading team
            </Heading>
            <Heading level="h7" color="#CDCED0" align="center">
              Meet SkyTrading team
            </Heading>
          </Flex>
          <Styled.ImagesContainer>
            <img src={image1} alt="team image" />
            <img src={image2} alt="team image" />
            <img src={image3} alt="team image" />
            <img src={image4} alt="team image" />
            <img src={image5} alt="team image" />
            <img src={image6} alt="team image" />
            <img src={image7} alt="team image" />
            <img src={image8} alt="team image" />
            <img src={image9} alt="team image" />
          </Styled.ImagesContainer>
        </Flex>
        <Flex direction="column" gap={16} width={isMobile ? '80%' : isSmallTablet ? '70%' : '60%'}>
          <Heading level="h3">Our mission</Heading>
          <Paragraph color="#CDCED0" size="xxl">
            Provide customers with tools that will increase the effectiveness of trading strategies and save their time.
          </Paragraph>
        </Flex>
        <Flex direction="column" gap={32} width={isMobile ? '80%' : isSmallTablet ? '70%' : '60%'} align="flex-start">
          <StarIcon />
          <Heading level="h7" color="#E1E1E1">
            “We believe that anyone can be a successful investor with the right tools in their hands.”
          </Heading>
          <Heading level="h6" color="#CDCED0">
            Maciek Biskup
          </Heading>
          <Paragraph color="#E1E1E1">CEO</Paragraph>
        </Flex>
        <Flex direction="column" gap={32}>
          <Heading level="h3">Our core values</Heading>
          <Flex gap={24} wrap="wrap" justify="center">
            {coreValues.map((value) => (
              <Card {...value} key={value.title} variant="horizontal" />
            ))}
          </Flex>
        </Flex>
        <Timeline />
        <Flex direction="column" gap={40}>
          <Flex direction="column" gap={16}>
            <Heading level="h3">Our investors</Heading>
            <Paragraph color="#CDCED0" size="xxl" align="center">
              We’re lucky to be part of amazing portfolios.
            </Paragraph>
          </Flex>
          <Flex
            justify="space-between"
            gap={isSmallMobile ? 60 : 10}
            width="100%"
            align={isSmallMobile ? 'center' : 'flex-start'}
            direction={isSmallMobile ? 'column' : 'row'}
          >
            <Flex gap={32} direction="column" width="300px">
              <Styled.InvestorCard style={{ cursor: 'default' }}>
                <ArkleyBrincLogo />
              </Styled.InvestorCard>
              <Paragraph size="xl" align="center">
                Arkley Brinc
              </Paragraph>
            </Flex>
            <Flex gap={32} direction="column" width="300px">
              <Styled.InvestorCard onClick={() => navigate('/parp')}>
                <ParpLogo />
              </Styled.InvestorCard>
              <Paragraph size="xl" align="center">
                Polska Agencja Rozwoju Przedsiębiorczości
              </Paragraph>
            </Flex>
          </Flex>
        </Flex>
        <WaitListSection />
      </Styled.Container>
    </Layout>
  );
};

const Styled = {
  Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 164px;
    margin: 180px auto;
  `,

  ImagesContainer: styled.section`
    width: 100%;
    max-width: 1780px;
    column-width: 340px;
    column-gap: 16px;
    padding: 16px;

    img {
      break-inside: avoid;
      width: 100%;
      margin-bottom: 12px;
    }
  `,

  InvestorCard: styled.button`
    width: 200px;
    height: 200px;
    padding: 12px;
    border-radius: 24px;
    display: grid;
    place-items: center;
    background: linear-gradient(306.76deg, rgba(42, 42, 42, 0.32) 49.14%, rgba(75, 75, 75, 0.32) 113.65%);
  `,
};
