import styled from 'styled-components/macro';

export const Gradient = styled.span<{ variant?: 'default' | 'reversed' }>`
  background: ${({ variant = 'default' }) =>
    variant === 'default'
      ? 'linear-gradient(103.18deg, #f6f6f6 -4.11%, #b784d7 106.6%)'
      : 'linear-gradient(103.18deg, #b784d7 -4.11%, #f6f6f6 106.6%)'};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
