import { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

import { Input, Button, DarkOverlay, WaitlistPopup } from 'common/components';
import { useResponsiveVariant } from 'common/hooks';
import { sendApiRequest } from 'common/utils';
import { useGlobalContext } from 'context';

type WaitlistFormProps = {
  isMainForm?: boolean;
};

export const WaitlistForm = ({ isMainForm }: WaitlistFormProps) => {
  const [isPopupShown, setIsPopupShown] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessfullySent, setIsSuccessfullySent] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const { isMobile } = useResponsiveVariant();
  const { setSectionsRefs } = useGlobalContext();
  const { isSmallMobile } = useResponsiveVariant();

  useEffect(() => {
    if (isMainForm && ref.current) {
      setSectionsRefs((prev) => [
        ...prev.filter((item) => item.name !== 'waitlist form'),
        { name: 'waitlist form', ref },
      ]);
    }
  }, [isMainForm, setSectionsRefs]);

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setIsSuccessfullySent(false);
    setError('');
  };

  const validateEmail = (email: string) => {
    if (!email) {
      setError('Required');
      return false;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
      setError('Invalid email address');
      return false;
    }

    setError('');
    return true;
  };

  const handleJoinClick = async () => {
    setIsLoading(true);
    if (validateEmail(email)) {
      await sendApiRequest({
        url: 'https://skytrading.io/api/joinWaitlist',
        data: { email },
        method: 'POST',
      });
      setIsPopupShown(true);
      setIsSuccessfullySent(true);
    } else {
      setIsSuccessfullySent(false);
    }
    setIsLoading(false);
  };

  return (
    <>
      {isPopupShown && (
        <>
          <DarkOverlay />
          <WaitlistPopup email={email} setEmail={setEmail} setIsPopupShown={setIsPopupShown} />
        </>
      )}
      <Styled.Container>
        <Input
          ref={ref}
          width={isMobile ? 'L' : 'XL'}
          type="text"
          name="email"
          placeholder="example@gmail.com"
          value={email}
          color={isMainForm ? '#191928' : '#101012'}
          onChange={handleChangeEmail}
          error={error ? { message: error } : undefined}
          postInputVariant={isSmallMobile && !isMainForm ? 'vertical' : 'horizontal'}
          postInputContent={
            <Button
              variant="primary"
              size={isMobile ? 'M' : 'XL'}
              onClick={handleJoinClick}
              loading={isLoading}
              success={isSuccessfullySent ? 'Email sent' : undefined}
            >
              Join waitlist
            </Button>
          }
        />
      </Styled.Container>
    </>
  );
};

const Styled = {
  Container: styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
  `,
};
