import { PropsWithChildren } from 'react';

import { Footer, NavBar } from 'common/components';
import { useScrollToTop } from 'common/hooks';

export const Layout = ({ children }: PropsWithChildren) => {
  useScrollToTop();

  return (
    <>
      <NavBar />
      {children}
      <Footer />
    </>
  );
};
