import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

export type NavListProps = {
  setIsNavListExpanded?: (isNavListExpanded: boolean) => void;
  isMobile?: boolean;
};

export const NavList = ({ isMobile }: NavListProps) => {
  return (
    <Styled.List isMobile={isMobile}>
      <Styled.NavLink to="/about">About us</Styled.NavLink>
      {/*<Styled.NavLink to="/pricing">Pricing</Styled.NavLink>*/}
    </Styled.List>
  );
};

const Styled = {
  List: styled.ul<{ isMobile?: boolean }>`
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-direction: ${({ isMobile }) => (isMobile ? 'column' : 'row')};
    width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
  `,

  NavLink: styled(NavLink)<{ isMobile?: boolean }>`
    display: flex;
    padding: ${({ isMobile }) => (isMobile ? '15px 12px' : '8px 12px')};
    justify-content: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
    align-items: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
    opacity: 0.75;
    border-bottom: ${({ isMobile, theme }) => (isMobile ? `1px solid ${theme.colors.grey[1]}` : 'none')};
    font-family: inherit;
    color: inherit;
    text-decoration: none;
  `,
};
