import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import { useResponsiveVariant } from 'common/hooks';
import {
  Flex,
  Heading,
  WaitlistForm,
  Gradient,
  WaitListSection,
  PartnersLogos,
  Card,
  Paragraph,
} from 'common/components';
import { ParagraphImageSection } from 'modules/LandingPage/components';
import AppVisualization from 'assets/Images/Rest/AppVisualization.png';
import StrategyCreatorImage from 'assets/Images/Rest/StrategyCreatorImage.png';
import BacktesterImage from 'assets/Images/Rest//BacktesterImage.png';
import OptimizerImage from 'assets/Images/Rest/OptimizerImage.png';
import MarketplaceImage from 'assets/Images/Rest/MarketplaceImage.png';
import IntegrationsImage from 'assets/Images/Rest/IntegrationsImage.png';
import RewardsImage from 'assets/Images/Rest/RewardsImage.png';
import backgroundPatter from 'assets/Images/Rest/background.png';
import { useGlobalContext } from 'context';

const sectionsContent = [
  {
    id: 'Strategy creator',
    preTitle: 'No-code',
    title: 'AI strategy builder',
    content:
      "Time is money in the trading world, and our AI Trading Chat is here to save you both! Experience the power of cutting-edge technology that simplifies strategy building and maximizes your trading potential. Don't miss out on the future of trading!",
    image: StrategyCreatorImage,
  },
  {
    id: 'AI Assistant',
    preTitle: 'AI Assistant',
    title: 'Most accurate backtester powered by TradingView',
    content:
      "Imagine a world where your trading strategies are no longer a guessing game. With our backtester and  TradingView's charts, you can unlock the power of historical data to refine your approach with unparalleled accuracy.",
    image: BacktesterImage,
  },
  {
    id: 'AI optimizer',
    preTitle: 'AI optimizer    Coming soon...',
    title: 'Adaptive AI strategy optimizer',
    content:
      'No more wasting 100s of hours to find the best strategy settings. With just one click, SkyTrading will do it for you in a few minutes. Like Adaptive Suspension anticipates every bump in the road our AI navigates the ever-changing market landscape, ensuring your portfolio experiences a smooth ride towards your financial goals.',
    image: OptimizerImage,
  },
];

const cards = [
  {
    image: MarketplaceImage,
    title: 'Marketplace',
    content:
      'With Marketplace, the power is in your hands. You can share your successful strategy and financially benefit from the growth of others',
  },
  {
    image: IntegrationsImage,
    title: 'Fast integrations',
    content:
      'Forget about complicated configurations. Our integration will ensure a smooth and hassle-free experience when connecting your trading account.',
  },
  {
    image: RewardsImage,
    title: 'Rewards',
    content:
      'Earn points for challenges and special events. Unlocks unique codes, skins, frames and other trophies that will make you stand out from the crowd.',
  },
];

export const Wall = () => {
  const { isMobile, isSmallMobile } = useResponsiveVariant();
  const { sectionsRefs } = useGlobalContext();
  const { state } = useLocation();

  useEffect(() => {
    if (state && state.scrollTo && sectionsRefs) {
      const waitlist = sectionsRefs.find(({ name }) => name === state.scrollTo);

      if (waitlist && waitlist.ref.current) {
        waitlist.ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        waitlist.ref.current.focus();
      }
    }
  }, [sectionsRefs, state]);

  return (
    <Styled.Container isMobile={isMobile}>
      <Flex gap={64} direction="column">
        <Flex gap={32} direction="column">
          <Flex gap={16} direction="column">
            <Heading level="h1" align="center">
              Maximize trading profits
            </Heading>
            <Heading level="h5" align="center">
              Automate your trading strategies with AI
            </Heading>
          </Flex>
          <WaitlistForm isMainForm />
        </Flex>
        <Flex direction="column" gap={20}>
          <PartnersLogos />
          <img src={AppVisualization} alt="app visualization" />
        </Flex>
      </Flex>
      <Flex direction="column" width={isSmallMobile ? '80vw' : isMobile ? '70vw' : '60vw'}>
        <Heading level="h4" align="center">
          <Gradient>“We believe everyone can be an effective investor with the right tools.”</Gradient>
        </Heading>
      </Flex>
      {sectionsContent.map((item, index) => (
        <ParagraphImageSection key={item.title} {...item} index={index} />
      ))}
      <Styled.CardsSection isMobile={isMobile}>
        <Heading level="h4" align="center">
          <Gradient>More to discover. More to be added. </Gradient>
        </Heading>
        <Flex gap={24} direction={isMobile ? 'column' : 'row'}>
          {cards.map((item) => (
            <Card {...item} key={item.title} />
          ))}
        </Flex>
      </Styled.CardsSection>
      <WaitListSection />
      <Flex direction="column" gap={24} align="flex-start" width={isSmallMobile ? '80vw' : isMobile ? '70vw' : '60vw'}>
        <Heading level="h6">
          Algorithmic Trading Platform - backtester strategy application for Equity, Forex and Crypto trading
        </Heading>
        <Paragraph color="#CDCED0">
          Under The European Regional Development Fund, Operational Program Eastern Poland, Action 1.1 Starting
          platforms for new ideas Submeasure 1.1.2 Development of startups in Eastern Poland under contract no.
          POPW.01.01.02-20-0029/21
        </Paragraph>
        <Paragraph color="#CDCED0">
          The aim of the project is to build investment strategy backtester for Equity, Forex and Crypto trading. Thanks
          to the built-in machine learning module, the application automatically improves strategies and shows optimal
          settings to improve results. Program will be used to conduct automated research on the influence of various
          factors on the value of the stock. In visual and numerical interpretations of the results will be available on
          the platform. To create an investment strategy will used backtest module that uses historical data. By using
          certain parameters the module is able to adapt to the conditions prevailing on the stock exchange.
        </Paragraph>
        <Paragraph color="#CDCED0">Total project value: PLN 1 189 734.00</Paragraph>
      </Flex>
    </Styled.Container>
  );
};

const Styled = {
  Container: styled.main<{
    isMobile: boolean;
  }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${({ isMobile }) => (isMobile ? '64px' : '168px')};
    padding: 152px 0;
    align-items: center;
    background-image: ${`url(${backgroundPatter})`};
    background-size: 100vw 1600px;
    background-repeat: no-repeat;
  `,

  CardsSection: styled.section<{
    isMobile: boolean;
  }>`
    width: 100%;
    background:
      radial-gradient(circle at top left, rgb(164, 139, 221) -30%, rgb(20, 22, 34) 50%) top left,
      radial-gradient(circle at bottom right, rgb(224, 118, 204) -30%, rgb(20, 22, 34) 50%) bottom right;
    background-size: ${({ isMobile }) => (isMobile ? '100% 51%' : '51% 100%')};
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    gap: 64px;
    padding: 80px 20px;
    align-items: center;
  `,
};
