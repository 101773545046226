import axios from 'axios';

type SendApiRequestArgs = {
  url: string;
  data?: unknown;
  method?: string;
  headers?: Record<string, string>;
};

export const sendApiRequest = async ({ url, data = null, method = 'GET', headers = {} }: SendApiRequestArgs) => {
  try {
    const response = await axios({
      method,
      url,
      headers,
      data,
    });
    if (response?.data) {
      return response.data;
    }
  } catch (e) {
    return console.log(e);
  }
};
