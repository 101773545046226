import { Devices } from 'types';

export type BasicColors = {
  paleGreen: string;
  blue: string;
  orange: string;
};

export type ColorPalettes = {
  purple: string[];
  black: string[];
  white: string[];
  elevation: string[];
  green: string[];
  red: string[];
  yellow: string[];
  grey: string[];
};

export type ButtonColors = {
  default: string;
  hover: string;
  pressed: string;
  inactive: string;
};

export type ZIndexes = {
  modalLeveling: (level: number) => number;
  tooltip: 400;
  notification: 300;
  modal: 104;
  modalOverlay: 103;
  dropdown: 200;
  overlay: 101;
  layoutBelow: 99;
};

export type Theme = {
  colors: BasicColors & ColorPalettes;
  buttons: Record<'default' | 'primary' | 'ghost' | 'gradient', ButtonColors>;
  zIndexes: ZIndexes;
};

export const theme: Theme = {
  colors: {
    purple: ['#9233c2', '#844ac7', '#6546a8', '#795fce', '#8074d7'],
    black: ['#141622', '#20222d', '#363842', '#4a4c55'],
    grey: ['#BDBEC1', '#43454e'],
    elevation: ['#141622', '#1D1F2B', '#262833', '#2F313B', '#373943', '#3F414B'],
    white: ['#fafbfb', '#bec3c7', '#969797'],
    red: ['#ff5458', '#FF989B', '#FF7679', '#993235'],
    green: ['#00ad74', '#66CEAC'],
    orange: '#EC9411',
    paleGreen: '#33BD90',
    yellow: ['#FBF7CF', '#F7EFA0', '#F4E670', '#F0DE41', '#ECD611', '#BDAB0E', '#8E800A', '#2F2B03', '#2F2B03'],
    blue: '#5737c2',
  },
  buttons: {
    default: {
      default: '#4a4c55',
      hover: '#5e6068',
      pressed: '#31323b',
      inactive: '#363842',
    },
    primary: {
      default: '#9c319e',
      hover: '#a646a8',
      pressed: '#7d277e',
      inactive: '#693470',
    },
    ghost: {
      default: 'transparent',
      hover: 'transparent',
      pressed: 'transparent',
      inactive: 'transparent',
    },
    gradient: {
      default: 'linear-gradient(117deg, #EB72C9 0%, #729BEB 100%)',
      hover: 'linear-gradient(117deg, #AF5DA1 0%, #5A7AB9 100%',
      pressed: 'linear-gradient(117deg, #E683D0 0%, #86A3EB 100%)',
      inactive: 'linear-gradient(117deg, #EB72C9 0%, #729BEB 100%)',
    },
  },
  zIndexes: {
    modalLeveling: (level: number) => 104 + level,
    tooltip: 400,
    notification: 300,
    modal: 104,
    modalOverlay: 103,
    dropdown: 200,
    overlay: 101,
    layoutBelow: 99,
  },
};

export type HeadingLevels = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7';
export type ParagraphSizes = 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
export type SizesMap = Record<Devices, { size: string; weight: string }>;

export const paragraphs: Record<ParagraphSizes, SizesMap> = {
  xs: {
    desktop: {
      size: '16px',
      weight: '400',
    },
    tablet: {
      size: '15px',
      weight: '400',
    },
    mobile: {
      size: '14px',
      weight: '400',
    },
  },
  s: {
    desktop: {
      size: '16px',
      weight: '600',
    },
    tablet: {
      size: '15px',
      weight: '600',
    },
    mobile: {
      size: '14px',
      weight: '600',
    },
  },
  m: {
    desktop: {
      size: '18px',
      weight: '400',
    },
    tablet: {
      size: '17px',
      weight: '400',
    },
    mobile: {
      size: '16px',
      weight: '400',
    },
  },
  l: {
    desktop: {
      size: '24px',
      weight: '400',
    },
    tablet: {
      size: '22px',
      weight: '400',
    },
    mobile: {
      size: '20px',
      weight: '400',
    },
  },
  xl: {
    desktop: {
      size: '24px',
      weight: '500',
    },
    tablet: {
      size: '22px',
      weight: '500',
    },
    mobile: {
      size: '20px',
      weight: '500',
    },
  },
  xxl: {
    desktop: {
      size: '28px',
      weight: '400',
    },
    tablet: {
      size: '26px',
      weight: '400',
    },
    mobile: {
      size: '24px',
      weight: '400',
    },
  },
};

export const headings: Record<HeadingLevels, SizesMap> = {
  h1: {
    desktop: {
      size: '80px',
      weight: '500',
    },
    tablet: {
      size: '74px',
      weight: '500',
    },
    mobile: {
      size: '68px',
      weight: '500',
    },
  },
  h2: {
    desktop: {
      size: '64px',
      weight: '500',
    },
    tablet: {
      size: '59px',
      weight: '500',
    },
    mobile: {
      size: '51px',
      weight: '500',
    },
  },
  h3: {
    desktop: {
      size: '48px',
      weight: '500',
    },
    tablet: {
      size: '44px',
      weight: '500',
    },
    mobile: {
      size: '40px',
      weight: '500',
    },
  },
  h4: {
    desktop: {
      size: '42px',
      weight: '500',
    },
    tablet: {
      size: '38px',
      weight: '500',
    },
    mobile: {
      size: '34px',
      weight: '500',
    },
  },
  h5: {
    desktop: {
      size: '36px',
      weight: '400',
    },
    tablet: {
      size: '33px',
      weight: '400',
    },
    mobile: {
      size: '30px',
      weight: '400',
    },
  },
  h6: {
    desktop: {
      size: '32px',
      weight: '500',
    },
    tablet: {
      size: '30px',
      weight: '500',
    },
    mobile: {
      size: '28px',
      weight: '500',
    },
  },
  h7: {
    desktop: {
      size: '32px',
      weight: '400',
    },
    tablet: {
      size: '30px',
      weight: '400',
    },
    mobile: {
      size: '28px',
      weight: '400',
    },
  },
};
