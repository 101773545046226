import { useMediaQuery } from 'react-responsive';

export const useResponsiveVariant = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1200 });
  const isSmallTablet = useMediaQuery({ maxWidth: 1000 });
  const isMobile = useMediaQuery({ maxWidth: 800 });
  const isSmallMobile = useMediaQuery({ maxWidth: 600 });
  const isVerySmallMobile = useMediaQuery({ maxWidth: 480 });

  return { isMobile, isTabletOrMobile, isSmallMobile, isSmallTablet, isVerySmallMobile };
};
