import { Devices } from 'types';

import { useResponsiveVariant } from './useResponsiveVariant';

export const useDeviceType = (): Devices => {
  const { isMobile, isSmallMobile, isTabletOrMobile, isSmallTablet } = useResponsiveVariant();

  if (isMobile || isSmallMobile) {
    return 'mobile';
  }

  if (isTabletOrMobile || isSmallTablet) {
    return 'tablet';
  }

  return 'desktop';
};
