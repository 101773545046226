import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';

import { SkytradingLogo } from 'assets/SVGs';
import { Flex, FooterSectionType, Paragraph, PartnersLogos } from 'common/components';
import { FooterInfoWithMedia, FooterSection } from 'common/components/Footer/components';
import EuropeanFoundBanner from 'assets/Images/Rest/EuropeanFoundBanner.png';

type DesktopFooterProps = {
  footerSections: FooterSectionType[];
};

export const DesktopFooter = ({ footerSections }: DesktopFooterProps) => {
  const navigate = useNavigate();

  return (
    <Flex direction="column" gap={120}>
      <PartnersLogos showTrustedLabel />
      <Styled.Container>
        <Styled.Info>
          <SkytradingLogo />
          <FooterInfoWithMedia />
        </Styled.Info>
        {footerSections.map((item) => (
          <FooterSection {...item} key={item.name} />
        ))}
      </Styled.Container>
      <Flex direction="column" width="100%" align="flex-start" gap={20}>
        <Styled.EndContainer>
          <Paragraph color="#E1E1E1">Copyright © 2024 SkyTrading</Paragraph>
          <a onClick={() => navigate('/terms')}>
            <Paragraph color="#E1E1E1">Terms of service</Paragraph>
          </a>
        </Styled.EndContainer>
        <Styled.ParpContainer>
          <img src={EuropeanFoundBanner} style={{ placeSelf: 'center' }} />
        </Styled.ParpContainer>
      </Flex>
    </Flex>
  );
};

const Styled = {
  Container: styled.footer`
    width: 100%;
    display: flex;
    padding: 0 8vw;

    & > * {
      width: 25%;
    }
  `,

  Info: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,

  RobotWrapper: styled.div`
    align-self: flex-end;
    margin: 0 10% 0 auto;
  `,

  EndContainer: styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 24px;
    padding: 0 8vw 0 8vw;
  `,

  ParpContainer: styled.div`
    width: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
  `,
};
