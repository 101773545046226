import styled from 'styled-components/macro';

import { Flex, Heading, Paragraph } from 'common/components';
import { Layout } from 'common/components/Layout';

export const TermsOfServiceView = () => (
  <Layout>
    <Styled.Container>
      <Flex direction="column" gap={24} align="flex-start">
        <Heading level="h6">Terms of use</Heading>
        <Flex direction="column" gap={16} align="flex-start">
          <Paragraph size="l" color="#CDCED0">
            This page contains a list of documents regulating the activity of SkyTrading.io
          </Paragraph>
          <Styled.List>
            <li>
              <a href="https://skytrading.io/files/PrivacyPolicy.pdf">
                <Paragraph color="#CDCED0">Privacy Policy</Paragraph>
              </a>
            </li>
            <li>
              <a href="https://skytrading.io/files/CookiePolicyEng.pdf">
                <Paragraph color="#CDCED0">Cookies Policy in English</Paragraph>
              </a>
            </li>
            <li>
              <a href="https://skytrading.io/files/CookiePolicyPol.pdf">
                <Paragraph color="#CDCED0">Cookies Policy in Polish</Paragraph>
              </a>
            </li>
            <li>
              <a href="#">
                <Paragraph color="#CDCED0">Terms and conditions of service</Paragraph>
              </a>
            </li>
          </Styled.List>
        </Flex>
      </Flex>
    </Styled.Container>
  </Layout>
);

const Styled = {
  Container: styled.div`
    width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 200px;
    margin: 180px auto;
  `,

  List: styled.ul`
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    list-style-type: none;
  `,
};
