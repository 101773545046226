import { useEffect } from 'react';
import styled from 'styled-components/macro';

import { Heading, Layout } from 'common/components';
import { sendApiRequest } from 'common/utils';

export const UnsubscribedView = () => {
  const confimNewsletterUnsubscribe = async (queryParameters: URLSearchParams) => {
    const confirmEmail = queryParameters.get('email_address');
    const token = queryParameters.get('code');
    if (!confirmEmail || !token) return;
    try {
      await sendApiRequest({
        url: `https://skytrading.io/api/unsubscribe?email_address=${confirmEmail}&code=${token}`,
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    confimNewsletterUnsubscribe(queryParameters);
  }, []);

  return (
    <Layout>
      <Styled.Container>
        <Heading level="h2">You have unsubscribed from the newsletter</Heading>
      </Styled.Container>
    </Layout>
  );
};

const Styled = {
  Container: styled.div`
    width: 60vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    margin: 0 auto;
  `,
};
