import { useResponsiveVariant } from 'common/hooks/useResponsiveVariant';

import { NavBarDesktop, NavBarMobile } from './components';

type NavBarProps = {
  displayLogo?: boolean;
};

export const NavBar = (props: NavBarProps) => {
  const { isMobile } = useResponsiveVariant();

  return isMobile ? <NavBarMobile {...props} /> : <NavBarDesktop {...props} />;
};
